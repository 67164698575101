<template>
  <div class="dswindow">
    <div class="electrical">
      <div class="btn"></div>
      <div class="title">
        {{ formData.deviceName }}
      </div>
      <div class="div_flex">
        <div class="number_of_failures">
         CO2浓度:<span :class="{ 'red': formData.co2 >= 700}">{{formData.co2 || ''}}</span> ppm
        </div>
        <div class="number_of_failures">
         PM2.5浓度:<span :class="{ 'red': formData.fineParticulateMatter >= 755}">{{formData.fineParticulateMatter || ''}}</span> ug/m
        </div>
      </div>
      <div class="div_flex">
        <div class="number_of_failures">
          TVOC浓度:<span :class="{ 'red': formData.tcov >= 0.6}">{{formData.tcov || ''}}</span> ug/m
        </div>
        <div class="number_of_failures">
          PM10浓度:<span :class="{ 'red': formData.pm10 >= 150}">{{formData.pm10 || ''}}</span> ug/m
        </div>
      </div>
      <div class="div_flex">
        <div class="number_of_failures">
          环境湿度:<span :class="{ 'red': envHumidityFilter(formData.envHumidity)}">{{formData.envHumidity || ''}}</span> %
        </div>
        <div class="number_of_failures">
          环境温度:<span :class="{ 'red': envTemperatureFilter(formData.envTemperature)}">{{formData.envTemperature || ''}}</span> °C
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import {getEnvSensors} from '@/api'
export default {
  data(){
    return{
      formData:{
        deviceName: "",
        envHumidity: "",
        pm10: "",
        fineParticulateMatter: "",
        tcov: "",
        envTemperature: "",
        co2: "",
        deviceStatus: "",
        modeSetting: ""
      },
      value:110,
      key:'',
      deviceNumber:''
    }
  },
  mounted() {
    this.$emit('loading')
    if(this.$route.query.deviceNumber){
      if (this.$store.state.token) {
        this.init(this.$route.query.deviceNumber)
      }
    }else{
      this.deviceName = '未获取到设备'
    }
  },
  watch: {
    '$store.state.token': {
      deep: true,
      handler(val) {
        this.init(this.$route.query.deviceNumber)
      }
    }
  },
  computed:{
    envHumidityFilter(){
      return (value)=>{
        const isTrue = {
          '1': (value < 40 || value > 60),
          '4': (value < 35 || value > 60),
          '6': false
        }
        const key = String(this.formData.modeSetting)
        return isTrue[key]
      }
    },
    envTemperatureFilter(){
      return (value)=>{
        const isTrue = {
          '1': value >= 27,
          '4': value >= 20,
          '6': false
        }
        const key = String(this.formData.modeSetting)
        return isTrue[key]
      }
    }
  },
  methods:{
    //获取设备数据
      init(i){
        const form = {deviceNumber:i}
        getEnvSensors(form).then(({data})=>{ 
          this.formData = data
        })
      }
  }
}
</script>

<style lang="scss" scoped>
.dswindow {
  width:238px;
  height: 194px;
  padding-top: 20px;
  .electrical {
    position: relative;
    width:510px;
    height: 184px;
    background: url('~@/assets/gd/ds_watercard.png') no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 16px 20px;
    color: #3acbe0;
    font-size: 18px; 
    font-family: MicrosoftYaHei;
    .btn{
      background: url('~@/assets/gd/ds_closebtn.png') no-repeat;
      background-size: 100% 100%;
      position: absolute;
      right: -19px;
      top: -20px;
      width: 38px;
      height: 44px;
    }
    .title{
      font-size:18px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #fff;
    }
    .div_flex{
      display: flex;
      justify-content: space-between;
      .number_of_failures{
        line-height: 15px;
        font-size: 15px;
        margin-top: 13px;
        .red{
          color: red;
        }
      }
    }
    .illustrate{
      display: flex;
      font-size: 16px;
      padding-top:14px;
      align-items: center;
      .left{
        width: 66%;
        height: 28px;
      }
      .left:nth-of-type(1) span{
        font-size: 24px;
      }
      .right{
        width: 40% ;
      }
    }
    span{
        color:#fdcc30;
        font-size: 24px;
        line-height: 17px;
    }
  }
}
</style>
